import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import notify from "../utils/notify";
import { updateUserStatus } from "../api";

const DashboardAdminClients = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { auth } = useContext(AuthContext);
  const { clients, getAllClientList } = useContext(DataContext);

  const fetchClients = async () => {
    await getAllClientList();
    setLoading(false);
  };

  useEffect(() => {
    if (!auth || !auth?.admin) {
      navigate("/", { replace: true });
    } else {
      fetchClients();
    }
    return () => setLoading(true);
  }, []);

  const handleStatusUpdate = async (e, data) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const { account_id, status } = data;
      const payload = {
        status: status === "active" ? "disabled" : "active",
      };
      const response = await updateUserStatus(account_id, "Client", payload);
      if (response) {
        notify("Status updated successfully", "success");
        setLoading(true);
        await fetchClients();
      }
      setSubmitting(false);
    } catch (error) {
      console.log(error);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">Clients</h3>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-lg-end">
                    {loading ? (
                      <Skeleton width={150} height={26} />
                    ) : (
                      <Link
                        to="/dashboard-admin-create-client"
                        className="font-size-3 font-weight-bold text-green text-uppercase"
                      >
                        <i className="fa fa-plus"></i>
                        <span className="ml-2">Add Company</span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className="bg-white shadow-8 rounded p-4 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <div className="table-responsive ">
                      <table className="table table-striped mt-3">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Company
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Wallet ID
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Location
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            >
                              Status
                            </th>
                            <th
                              scope="col"
                              className="pl-4 border-0 font-size-4 font-weight-normal"
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            [1, 2, 3, 4, 5].map((index) => (
                              <tr key={index} className="border border-color-2">
                                <th className="table-y-middle pl-6 border-0 py-7 min-width-px-235">
                                  <Skeleton width={200} height={26} />
                                </th>
                                <td className="table-y-middle py-7 min-width-px-135">
                                  <Skeleton width={150} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-135">
                                  <Skeleton width={150} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-125">
                                  <Skeleton width={100} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-200">
                                  <Skeleton width={70} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-80">
                                  <Skeleton width={50} height={26} />
                                </td>
                                <td className="table-y-middle py-7 min-width-px-80">
                                  <div className="d-flex">
                                    <Skeleton
                                      width={70}
                                      height={26}
                                      containerClassName="mr-2"
                                    />
                                    <Skeleton width={70} height={26} />
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : clients.length > 0 ? (
                            clients.map((client, index) => (
                              <tr key={index} className="border border-color-2">
                                <th
                                  scope="row"
                                  className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                >
                                  <div className="">
                                    <Link
                                      to={`/client-profile/${client?.account_id}`}
                                      state={{ id: client.account_id }}
                                      className="font-size-4 mb-0 font-weight-semibold text-black-2"
                                    >
                                      {client?.company}
                                    </Link>
                                  </div>
                                </th>
                                <td className="table-y-middle py-7 min-width-px-135">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {`${client?.firstname} ${client?.lastname}`}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-125">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {client?.account_id}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-155">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {client?.city}
                                    {", "}
                                    {client?.country}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-80">
                                  <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                    {client?.status}
                                  </h3>
                                </td>
                                <td className="table-y-middle py-7 min-width-px-80 d-flex">
                                  <button
                                    onClick={(e) =>
                                      handleStatusUpdate(e, client)
                                    }
                                    className={`btn btn-outline text-uppercase font-size-3 ${
                                      client?.status === "active"
                                        ? "text-red-2"
                                        : "teal"
                                    } focus-reset`}
                                  >
                                    {submitting
                                      ? "..."
                                      : client?.status === "active"
                                      ? "Disable"
                                      : "Activate"}
                                  </button>
                                  <Link
                                    to={`/admin-edit-client/${client.account_id}`}
                                    state={{ id: client.account_id }}
                                    className="btn btn-outline text-uppercase font-size-3 text-green focus-reset"
                                  >
                                    Edit
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="border border-color-2">
                              <td
                                colSpan={6}
                                className="pl-6 border-0 py-7 pr-0"
                              >
                                <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                  No clients found at the moment
                                </h4>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardAdminClients;
